import React, { useState, useEffect, useCallback } from "react";
import "../../shared/imports/hardcoded-components/deal.css";
import "../../shared/imports/hardcoded-components/timer.css";
import "../../shared/imports/hardcoded-components/location.css";
import "../../shared/imports/hardcoded-components/collapse.css";
import "../../shared/imports/hardcoded-components/progress.css";
import "./home-item.css";
import { AppRoute } from "../../shared/const";
import "../../Components/pwa-header/pwa-header.css";
import PWANav from "../../Components/pwa-nav/pwa-nav";
import PWAHeader from "../../Components/pwa-header/pwa-header";
import Container from "../../Components/container/container";
import Back from "../../Components/back/back";
import getSelectedVoucher from "../../hooks/useSelectedVoucher";
import { GetImage } from "../../services/api/getImage";
import {
  GetVoucherDetail,
  GetVoucherDetailResponse,
} from "../../services/api/getVoucherDetail";
import BrandLogoPlaceholder from "../../assets/placeholders/logo-placholder.jpg";
import { ReactComponent as Calander } from "../../assets/symbols/calendar.svg";
import { ReactComponent as Location } from "../../assets/symbols/geo.svg";
import { ReactComponent as Chevrondown } from "../../assets/symbols/chevron-down-mini.svg";
import Dropdown from "../../Components/dropdown/dropdown";
import openNotification from "../../Components/Antd/Notification/Notification";
import { GetVoucher, GetVoucherResponse } from "../../services/api/getVouchers";
import getLoggedInUser from "../../hooks/useLoggedInUser";
import getLocations from "../../services/api/getLocation";
import { getVoucherBank } from "../../services/api/milestoneService";
import getSelectedProgram from "../../hooks/useSelectedProgram";

interface Location {
  id: number;
  businessName: string;
  locationName: string;
  address: string;
  region: string | null;
  currencyCode: string;
  memberReceiptStampAmount: string;
  memberReceiptStampsForAmount: number;
  // Add other properties as needed
}

// Add interface for milestone rewards
interface MilestoneReward {
  bankVoucherCampaignId: number;
  stampingDigitalVoucherIssueThreshold: number;
  claimed?: boolean;
}

// Add interface for voucher bank item
interface VoucherBankItem {
  id: number;
  campaignName: string;
}

function HomeItem() {
  const selectedVoucher = getSelectedVoucher();
  const [isLoading, setIsLoading] = useState(false);
  const [imageSrc, setImageSrc] = useState<string | null>(BrandLogoPlaceholder);
  const [logoSrc, setLogoSrc] = useState<string | null>(BrandLogoPlaceholder);
  const [voucherDetail, setVoucherDetail] =
    useState<GetVoucherDetailResponse>();
  const voucherImage = selectedVoucher?.imageId || "";
  const brandImage = selectedVoucher?.Program.imageURL || "";
  const [vouchers, setVouchers] = useState<GetVoucherResponse>();
  const selectedProgram = getSelectedProgram();
  const loggedInUser = getLoggedInUser();
  const [newVoucherDetail, SetNewVoucherDetail] =
    useState<GetVoucherDetailResponse>();
  const [locations, setLocations] = useState<Location[]>([]);
  const [milestoneVisitCount, setMilestoneVisitCount] = useState<number>(0);
  // Add state for milestone rewards
  const [milestoneRewards, setMilestoneRewards] = useState<MilestoneReward[]>(
    [],
  );
  const milestoneData = selectedVoucher?.milestoneData;
  // Add state for voucher bank
  const [voucherBank, setVoucherBank] = useState<VoucherBankItem[]>([]);

  const handleGetVoucherDetail = useCallback(async () => {
    setIsLoading(true);

    try {
      // console.log(selectedVoucher?.code ?? "");
      // Store this in redux store
      // Possibly use redux toolkit's query: https://redux-toolkit.js.org/rtk-query/overview
      const response = await GetVoucherDetail(selectedVoucher?.code ?? "");
      // console.log(response);
      setVoucherDetail(response); // Update the type of 'memberships' state
      //openNotification("success", `Get Membership success`, null);
    } catch (err) {
      console.error(err);
      openNotification("error", `Fail to load memberships`, null);
    } finally {
      setIsLoading(false);
    }
  }, []);
  useEffect(() => {
    handleGetVoucherDetail();
  }, [handleGetVoucherDetail]);

  const handleGetVoucher = useCallback(async () => {
    setIsLoading(true);

    try {
      //console.log(loggedInUser?.uuid ?? "");
      const response = await GetVoucher(loggedInUser?.uuid ?? "");
      response.forEach((element) => {
        if (selectedVoucher)
          if (selectedVoucher && element.code === selectedVoucher.code) {
            //console.log(
            //`element: ${element.code}, selectedVoucher: ${selectedVoucher.code}`,
            //);
            console.log(voucherDetail);
            // console.log(element);
            if (voucherDetail) {
              SetNewVoucherDetail({
                ...voucherDetail,
                recordedVisits: element.recordedVisits,
                unlockVisitThreshold: element.unlockVisitThreshold,
              });
              console.log("recorded added");
            }
          }
      });
      //loop through the vouchers and voucher.code === selectedVoucher.code
      // const selectedProgram = voucherDetail?.Program;

      // let totalVoucherCount = response.reduce((total, voucher) => {
      //   if (voucher.Program.uuid === selectedProgram?.uuid) {
      //     if (
      //       voucher.unlockVisitThreshold !== 0 &&
      //       voucher.availableRedemptionCount !== 0
      //     ) {
      //       return total + voucher.availableRedemptionCount + 1;
      //     } else {
      //       return total + 1;
      //     }
      //   } else {
      //     return total;
      //   }
      // }, 0);
      // setVoucherCount(totalVoucherCount);
      // openNotification("success", `Get vouchers success`, null);
    } catch (err) {
      console.error(err);
      openNotification("error", `Fail to load vouchers`, null);
    } finally {
      setIsLoading(false);
    }
  }, [voucherDetail]);

  useEffect(() => {
    if (voucherDetail) {
      handleGetVoucher();
    }
  }, [handleGetVoucher]);

  useEffect(() => {
    const downloadImage = async (url: string) => {
      try {
        return await GetImage(url);
      } catch (error) {
        console.error("Failed to download image", error);
        return null;
      }
    };

    Promise.all([downloadImage(voucherImage), downloadImage(brandImage)]).then(
      ([downloadedVoucherImage, downloadedBrandImage]) => {
        setImageSrc(downloadedVoucherImage);
        setLogoSrc(downloadedBrandImage);
      },
    );
  }, [voucherImage, brandImage]);
  // console.log(voucherDetail);
  const requirements = voucherDetail?.requirements.requirements;

  // Update progress calculation
  const progressBarWidth =
    selectedVoucher?.voucherType === "Milestone Voucher"
      ? `${(((milestoneData?.visitCount ?? 0) % (newVoucherDetail?.unlockVisitThreshold ?? 1)) / (newVoucherDetail?.unlockVisitThreshold ?? 1)) * 100}%`
      : `${(((newVoucherDetail?.recordedVisits ?? 0) % (newVoucherDetail?.unlockVisitThreshold ?? 1)) / (newVoucherDetail?.unlockVisitThreshold ?? 1)) * 100}%`;

  const items = voucherDetail?.termsNConditions
    .replace(/\\n/g, "\n")
    .split("\n")
    .map((item) => item.replace(/^\d+\./, "").trim())
    .filter(Boolean);
  //format expiry date to DDMMYYYY
  const expriyDate = new Date(
    selectedVoucher?.expiryDate ?? "",
  ).toLocaleDateString();
  // console.log(voucherDetail);

  const fetchLocations = useCallback(async () => {
    if (!newVoucherDetail?.Program.uuid) {
      console.log("No program UUID available");
      return;
    }

    try {
      //console.log(
      //  "Making API call to fetch locations for:",
      //  newVoucherDetail.Program.uuid,
      //);
      const locationData = await getLocations(newVoucherDetail.Program.uuid);
      //console.log("Received location data:", locationData);
      setLocations(locationData);
    } catch (err) {
      console.error("Failed to fetch locations:", err);
      openNotification("error", "Failed to load location information", null);
    }
  }, [newVoucherDetail?.Program.uuid]);

  useEffect(() => {
    if (
      newVoucherDetail?.memberReceiptScanning &&
      newVoucherDetail?.Program.uuid
    ) {
      //console.log(
      //  "Fetching locations for program:",
      //  newVoucherDetail.Program.uuid,
      //);
      fetchLocations();
    }
  }, [newVoucherDetail, fetchLocations]);

  const formatCurrency = (currencyCode: string, amount: string) => {
    return `${currencyCode} ${amount}`;
  };

  useEffect(() => {
    if (selectedVoucher?.voucherType === "Milestone Voucher") {
      // Get visit count from voucher detail
      const visitCount = newVoucherDetail?.recordedVisits ?? 0;
      setMilestoneVisitCount(visitCount);
    }
  }, [selectedVoucher?.voucherType, newVoucherDetail?.recordedVisits]);

  // Add useEffect to fetch voucher bank data
  useEffect(() => {
    const fetchVoucherBank = async () => {
      if (selectedProgram?.id) {
        try {
          const response = await getVoucherBank(selectedProgram?.id);
          setVoucherBank(response.data);
        } catch (err) {
          console.error("Failed to fetch voucher bank:", err);
        }
      }
    };
    fetchVoucherBank();
  }, [selectedProgram?.id]);

  return (
    <>
      <div className="pwa-page">
        <PWAHeader />
        <Container className="home-item">
          <div className="home-item__back">
            <Back to={AppRoute.Home}>Voucher Details</Back>
          </div>
          <div className="home-item__body">
            <div className="deal">
              <div className="deal__card">
                <div
                  className="deal__stripe"
                  style={{ background: 935523 }}
                ></div>
                <div className="deal__head">
                  <div className="deal__venue">
                    {selectedVoucher?.Program.name}
                  </div>
                </div>
                <div className="deal__body">
                  <div className="deal__preview">
                    <img
                      className="deal__illustration"
                      src={voucherImage ? imageSrc || "" : BrandLogoPlaceholder}
                      alt=""
                    />
                    <div className="deal__leaf">
                      <img
                        className="deal__logo"
                        src={brandImage ? logoSrc || "" : BrandLogoPlaceholder}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="deal__content">
                    <div className="deal__offer-heading">
                      {selectedVoucher?.campaignName}
                    </div>
                    <div className="deal__offer-value">
                      {selectedVoucher?.valueDescription}
                    </div>
                  </div>
                </div>
              </div>
              {newVoucherDetail &&
                newVoucherDetail?.unlockVisitThreshold != 0 && (
                  <div className="deal__card">
                    <div className="deal__progress-inner">
                      <div className="deal__textual-progress">
                        <div className="deal__unlocking-heading">
                          Unlocked
                          <br /> Progress
                        </div>
                        <div className="deal__stats">
                          <div className="deal__unlocked-total">
                            {selectedVoucher?.voucherType ===
                            "Milestone Voucher"
                              ? (milestoneData?.visitCount ?? 0) %
                                (newVoucherDetail?.unlockVisitThreshold ?? 1)
                              : (newVoucherDetail?.recordedVisits ?? 0) %
                                (newVoucherDetail?.unlockVisitThreshold ??
                                  1)}{" "}
                            / {newVoucherDetail?.unlockVisitThreshold}
                          </div>
                          <div className="deal__numbers">Number of stamps</div>
                        </div>
                      </div>
                      <div className="deal__visual-progress">
                        <div className="progress">
                          <div
                            className="progress__value"
                            style={{
                              width: progressBarWidth,
                              backgroundColor: "#4B76DB",
                            }}
                          ></div>
                        </div>
                      </div>
                      {selectedVoucher?.voucherType === "Milestone Voucher" && (
                        <div className="deal__milestone-rewards">
                          <h3>Milestone Rewards</h3>
                          <table>
                            <thead>
                              <tr>
                                <th>Stamps Required</th>
                                <th>Reward</th>
                              </tr>
                            </thead>
                            <tbody>
                              {[...(milestoneData?.rewards || [])]
                                .sort(
                                  (a, b) =>
                                    a.stampingDigitalVoucherIssueThreshold -
                                    b.stampingDigitalVoucherIssueThreshold,
                                )
                                .map((reward) => {
                                  const voucherName =
                                    voucherBank.find(
                                      (v) =>
                                        v.id === reward.bankVoucherCampaignId,
                                    )?.campaignName || "Loading...";
                                  return (
                                    <tr key={reward.bankVoucherCampaignId}>
                                      <td>
                                        {
                                          reward.stampingDigitalVoucherIssueThreshold
                                        }{" "}
                                        stamps
                                      </td>
                                      <td>{voucherName}</td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      )}
                    </div>
                  </div>
                )}

              {newVoucherDetail?.memberReceiptScanning &&
                locations.length > 0 && (
                  <div className="__card">
                    <div className="__expiration">
                      <div className="__expiration-details">
                        <div className="__days-left">
                          Conversion from your receipt to your stamp card
                        </div>
                        {locations.length === 1 ? (
                          <div className="__single_conversion">
                            {formatCurrency(
                              locations[0].currencyCode,
                              locations[0].memberReceiptStampAmount,
                            )}{" "}
                            equals {locations[0].memberReceiptStampsForAmount}{" "}
                            stamp(s)
                          </div>
                        ) : (
                          <>
                            <div className="__conversion_header">
                              <div className="__header_location">Location</div>
                              <div className="__header_conversion">
                                Conversion Rate
                              </div>
                            </div>
                            <div className="__conversion_rates">
                              {locations.map((location) => (
                                <div
                                  key={location.id}
                                  className="__conversion_rate"
                                >
                                  <div className="__location_name">
                                    {location.locationName}
                                  </div>
                                  <div className="__conversion_details">
                                    {formatCurrency(
                                      location.currencyCode,
                                      location.memberReceiptStampAmount,
                                    )}{" "}
                                    equals{" "}
                                    {location.memberReceiptStampsForAmount}{" "}
                                    stamp(s)
                                  </div>
                                </div>
                              ))}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              {(Number(selectedVoucher?.startDate) ?? Infinity) < 10000 && (
                <div className="__card">
                  <div
                    className="__stripe"
                    style={{ background: 935523 }}
                  ></div>
                  <div className="__expiration">
                    <Calander className="__calendar" />
                    <div className="__expiration-details">
                      <div className="__days-left">
                        {selectedVoucher?.startDate} Day(s) left
                      </div>
                      <div className="__expiration-date">
                        This offer expires on {expriyDate.toString()}
                      </div>
                    </div>
                  </div>
                  {/* <div className="deal__full-address">
                      <div className="location location--large">
                      <Location className="location__icon" />
                      {selectedVoucher?.Program.physicalAddress}
                      </div>
                      </div> */}
                </div>
              )}
              <div className="deal__card deal__card--details">
                <div
                  className="deal__stripe"
                  style={{ background: 935523 }}
                ></div>
                <div className="deal__details">
                  <div className="deal__article">
                    <Dropdown
                      content={
                        <div className="wysiwyg">
                          <p style={{ textAlign: "left", paddingTop: "5px" }}>
                            {voucherDetail?.description}
                          </p>
                          <h3>Requirements:</h3>
                          <ol>
                            {requirements
                              ? requirements
                                  .filter(
                                    (requirement: any) => requirement !== null,
                                  )
                                  .map((requirement: any, index: number) => (
                                    <li key={index}>{requirement}</li>
                                  ))
                              : "No requirements"}
                          </ol>
                        </div>
                      }
                    >
                      About This Voucher
                    </Dropdown>
                  </div>
                  <div className="deal__article">
                    <Dropdown
                      content={
                        <ol style={{ lineHeight: "1.8" }}>
                          {items?.map((item, index) => (
                            <li
                              key={index + 1}
                              style={{ marginBottom: "1rem" }}
                            >
                              {item}
                            </li>
                          ))}
                        </ol>
                      }
                    >
                      Terms And Conditions
                    </Dropdown>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
        <PWANav />
      </div>
    </>
  );
}

export default HomeItem;
